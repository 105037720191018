/* eslint-disable */
import React, { useLayoutEffect, useState, useRef } from 'react';
import './TeachingList.scss';
import teaching1Image from './images/teaching1.png';
import teaching2Image from './images/teaching2.png';
import teaching3Image from './images/teaching3.png';
import teaching4Image from './images/teaching4.png';
import teaching5Image from './images/teaching5.png';
import teaching6Image from './images/teaching6.png';
import teaching7Image from './images/teaching7.png';

const renderTeachingItem = (item, index) => {
    const { title, image } = item;

    return (
        <div key={index} className="teachingItem">
            <div className="teachingTitle">{title}</div>
            <img className="teachingImage" src={image} />
        </div>
    );
};

const TeachingList = () => {
    return <div className="teachingList">{list.map(renderTeachingItem)}</div>;
};

const list = [
    {
        title: '① 点击【立即下载】,弹出界面后点击\n【允许】,如图',
        image: teaching1Image,
    },
    {
        title: '② 弹出如下图后, 点击【关闭】后再点击\n【允许】',
        image: teaching2Image,
    },
    {
        title: '③ 弹出下面界面后, 点击【已下载描述文件】',
        image: teaching3Image,
    },
    {
        title: '④ 弹出安装界面, 点击【安装】, 此时会提示输入密码',
        image: teaching4Image,
    },
    {
        title: '⑤ 输入密码后弹出如下界面, 点击【安装】',
        image: teaching5Image,
    },
    {
        title: '⑥ 完成验证',
        image: teaching6Image,
    },
    {
        title: '⑦ 安装完成, 重新打开应用, 即可登录',
        image: teaching7Image,
    },
];

export default TeachingList;
