/* eslint-disable */
import React from 'react';
import './DownloadPage.scss';
import logo from './images/logo.png';
import mainImage from './images/main.png';
import buttonImage from './images/button.png';
import prevImage from './images/prev.png';
import prevLockImage from './images/prevLock.png';
import nextImage from './images/next.png';
import nextLockImage from './images/nextLock.png';
import pay1Image from './images/pay1.png';
import pay2Image from './images/pay2.png';
import pay3Image from './images/pay3.png';
import pay4Image from './images/pay4.png';
import pay5Image from './images/pay5.png';
import pay6Image from './images/pay6.png';
import pay7Image from './images/pay7.png';
import pay8Image from './images/pay8.png';
import cert1Image from './images/cert1.png';
import cert2Image from './images/cert2.png';
import cert3Image from './images/cert3.png';
import scrollButton from './images/scrollButton.png';
import customerImg from './images/customerImg.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import TeachingList from './TeachingList';

const renderPayItem = (item) => {
    return (
        <div className="payListItem" key={item.title}>
            <img className="payListImg" src={item.image} />
            <div className="payListText">{item.title}</div>
        </div>
    );
};

const DownloadPage = (props) => {
    const { isAndroid, handleInstall, handleCustomer } = props;

    return (
        <div className="main">
            <img className="logo" src={logo} />
            <div className="customerDiv" onClick={handleCustomer}>
                <img className="customerImg" src={customerImg} />
                <span>在线客服</span>
            </div>
            <Carousel
                className="carousel"
                autoPlay={false}
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div className="prevImageDiv" onClick={clickHandler}>
                            <img className="arrowImage" src={hasPrev ? prevImage : prevLockImage} />
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div className="nextImageDiv" onClick={clickHandler}>
                            <img className="arrowImage" src={hasNext ? nextImage : nextLockImage} />
                        </div>
                    );
                }}
                showStatus={false}
                showThumbs={false}
            >
                <div>
                    <div className="title">选博猫 赢世界</div>
                    <div className="subTitleBar">
                        <div className="line" />
                        <div className="subTitle">王者雄心 智胜千里</div>
                        <div className="line" />
                    </div>
                    <img className="mainImage" src={mainImage} />
                </div>
                <div>
                    <div className="title">支持8种主流存款方式</div>
                    <div className="subTitleBar">
                        <div className="subMinTitle">
                            微信 支付宝 网银支付 快捷 京东 QQ支付 银联扫码 网银转账
                        </div>
                    </div>
                    <div className="payList">
                        {payList.map((item, index) => {
                            return (
                                <div className="payListBar" key={index}>
                                    {item.list.map(renderPayItem)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <div className="title">博彩权威认证</div>
                    <div className="subTitleBar">
                        <div className="subTitle">公正诚信，开放共赢</div>
                    </div>
                    {certList.map((item, index) => {
                        return (
                            <div className="certImage" key={index}>
                                <img src={item} />
                            </div>
                        );
                    })}
                </div>
            </Carousel>
            <div className="promptText">
                需在同一网络环境下下载安装注册, 请勿切换网络; 若无法正常安装, 请使用手机
                自带浏览器打开本页面(苹果浏览器Safari, 谷歌浏览器Chrome)
            </div>
            <img className="sendButton" src={buttonImage} onClick={handleInstall} />
            {!isAndroid && (
                <>
                    <div className="scrollText">下滑查看安装教程</div>
                    <img className="scrollButton" src={scrollButton} />
                </>
            )}
            {!isAndroid && <TeachingList />}
        </div>
    );
};

const payList = [
    {
        list: [
            {
                title: '微信支付',
                image: pay1Image,
            },
            {
                title: '支付宝支付',
                image: pay2Image,
            },
        ],
    },
    {
        list: [
            {
                title: '网银支付',
                image: pay3Image,
            },
            {
                title: '银联支付',
                image: pay4Image,
            },
            {
                title: '虚拟币支付',
                image: pay5Image,
            },
        ],
    },
    {
        list: [
            {
                title: 'QQ支付',
                image: pay6Image,
            },
            {
                title: '银联扫码',
                image: pay7Image,
            },
            {
                title: '网银转账',
                image: pay8Image,
            },
        ],
    },
];

const certList = [cert1Image, cert2Image, cert3Image];

export default DownloadPage;
