/* eslint-disable */
import React, { useRef, useState } from 'react';
import './NewPage.scss';
import backPlay from './images/backPlay.mp4';
import newCustomerImg from './images/newCustomerImg.png';
import installButton from './images/installButton.png';

const NewPage = (props) => {
    const NewPageRef = useRef(null);
    const [videoCurrent, setVideoCurrent] = useState({});
    const { handleInstall, handleCustomer } = props;
    const { clientHeight, clientWidth } = videoCurrent;

    return (
        <div className="NewPage">
            {clientHeight && (
                <>
                    <img className="newCustomerImg" src={newCustomerImg} onClick={handleCustomer} />
                </>
            )}
            <video
                ref={NewPageRef}
                className="video"
                autoPlay
                muted
                loop
                playsInline
                // controls
                onLoadedMetadata={() => {
                    // 等video影片讀完才知道寬高
                    setVideoCurrent(NewPageRef.current);
                }}
                onError={(e) => {
                    console.log('kailog', e.target.error);
                }}
            >
                <source src={backPlay} type="video/mp4" />
            </video>
            {clientHeight && (
                <>
                    <img className="newCustomerImg" src={newCustomerImg} onClick={handleCustomer} />
                    <img className="installButton" src={installButton} onClick={handleInstall} />
                </>
            )}
        </div>
    );
};

export default NewPage;
