/* eslint-disable */
import React, { useLayoutEffect, useState, useRef } from 'react';
import './App.scss';
import alertWeixinImage from './images/alertWeixin.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DownloadPage from './DownloadPage';
import NewPage from './NewPage';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import md5 from 'md5';
import platform from 'platform';

const handleDownload = () => {
    if (isAndroid) {
        // const link = 'https://dl.phl5b.org/apks/nbm_stg/nbm.apk';
        const link = 'https://download.lbmu520.com/apk/nbm_205.apk';

        window.location.href = link;
    } else {
        const link = 'https://8bhnk.com/1xPa';

        window.open(link);
    }
};

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
};

const isAndroid = platform.os.family === 'Android';

const isWeixn = () => {
    var ua = navigator.userAgent.toLowerCase();

    return ua.match(/MicroMessenger/i) == 'micromessenger';
};

const handleGetIP = () => {
    return fetch('https://api-ipv4.ip.sb/geoip').then((res) => {
        return res.json();
    });
};

const handleGetFP = async (ip) => {
    let fpResult = '';
    let obj = {};
    // const options = {
    //     excludes: {
    //         userAgent: true,
    //         language: true,
    //         sessionStorage: true,
    //         localStorage: true,
    //         indexedDb: true,
    //         addBehavior: true,
    //         openDatabase: true,
    //         openDatabase: true,
    //         doNotTrack: true,
    //         plugins: true,
    //         canvas: true,
    //         webgl: true,
    //         adBlock: true,
    //         fonts: true,
    //         audio: true,
    //         enumerateDevices: true,
    //     },
    // };

    // const fp = await FingerprintJS.load();
    // const { components: resultComponents } = await fp.get(options);

    // Object.keys(resultComponents).map((key, index) => {
    //     obj[key] = resultComponents[key].value;
    // });

    // delete obj.fontsFlash;
    // delete obj.timezone;
    // delete obj.hasLiedBrowser;
    // delete obj.availableScreenResolution;
    // delete obj.colorDepth;
    // delete obj.deviceMemory;
    // delete obj.platform;
    // delete obj.pixelRatio;
    // delete obj.vendorFlavors;
    // delete obj.cookiesEnabled;
    // delete obj.openDatabase;

    // // use width
    // if (isAndroid) {
    //     obj.screenWidth = obj.screenResolution[1];
    // }
    // delete obj.screenResolution;

    //add ip
    obj.IP = ip;
    //add os version
    const getOSVersion = () => {
        const verArr = platform.os.version.split('.');
        verArr.length = 2;

        return `${verArr[0]}.${verArr[1] ? verArr[1] : '0'}`;
    };

    obj.OSVersion = getOSVersion();
    obj.OSFamily = platform.os.family;

    let log = '';

    for (var key in obj) {
        fpResult = fpResult + obj + obj[key];
        log = log + `${key} = ${obj[key]}` + '\n';
    }

    let src = fpResult;
    fpResult = md5(src);

    //alert(`fp = ${fpResult}\n ${log}`);
    return {
        fpResult,
    };
};

const handleSendFp = async () => {
    // if (navigator.userAgent.match('Firefox')) {
    //     alert('不支援 Firefox');
    //     return;
    // }

    const { ip } = await handleGetIP();
    const { fpResult } = await handleGetFP(ip);

    const search = window.location.search;
    // ?isNewPage=true&reg=code%3D71900c39%26invite%3D
    const params = new URLSearchParams(search).get('reg');
    // const url = 'http://joy188.mobile.affpstore.com:55001/register';
    const url = 'https://mobile.nbmpstore.com:55001/register';
    const data = {
        appId: 'nbm',
        fp: fpResult,
        params,
    };

    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        //mode: 'no-cors',
    })
        .then((res) => {
            return res.text();
            // return res.text();
        })
        .then((res) => {
            console.log('kailog', res);
        })
        .catch((error) => {
            console.log('kailog Error:', error);
        })
        .finally(() => {});
};

const handleInstall = () => {
    handleDownload();
    handleSendFp();
};

const handleGetValidInfo = async () => {
    const { ip } = await handleGetIP();
    const { fpResult } = await handleGetFP(ip);

    fetch('https://mobile.nbmpstore.com:55001/getValidInfo', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            appId: 'nbm',
            fp: fpResult,
        }),
    })
        .then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                console.log('kailog FpUrl Error', res.status);

                return {
                    params: 'invalid',
                };
            }
        })
        .then((res) => {
            console.log('kailog', res);

            if (res?.params && res?.params !== 'invalid') {
                console.log('kailog', res.params);
            }
        })
        .catch((error) => {
            console.log('kailog FpUrl Error:', error);
        });
};

const handleGetFpToApp = async () => {
    const { ip } = await handleGetIP();
    const { fpResult } = await handleGetFP(ip);

    window.ReactNativeWebView.postMessage(fpResult);
};

const handleCustomer = () => {
    const csUrl = 'https://h5.ibmao88.com/pv3/cs/forward';
    const myWin = window.open(csUrl);

    if (!myWin) {
        window.location.href = csUrl;
    }
};

const handleCustomerApi = () => {
    fetch(`https://api.ibmao88.com/v3/customer/guest/url/${isAndroid ? 'android' : 'ios'}/user`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            if (res.url) {
                const myWin = window.open(res.url);

                if (!myWin) {
                    window.location.href = res.url;
                }
            }
        })
        .catch((error) => {
            console.log('kailog Customer Error:', error);
        });
};

const App = () => {
    const [width] = useWindowSize();
    const AppRef = useRef(null);
    const [showAlertWeixin, setShowAlertWeixin] = useState(isWeixn());
    const params = new URL(document.location).searchParams;
    const isNewPage = params.get('isNewPage') === 'true';

    useLayoutEffect(() => {
        const designWidth = 750;
        const rootElement = document.documentElement;
        const scale = AppRef.current.clientWidth / designWidth;
        const designFontSize = 10 * scale;

        rootElement.style.fontSize = `${designFontSize}px`;
    }, [width]);

    useLayoutEffect(() => {
        if (window?.ReactNativeWebView?.postMessage) {
            handleGetFpToApp();
        }

        // handleSendFp();
        // handleGetValidInfo();
    }, []);

    return (
        <div className="App" ref={AppRef}>
            {showAlertWeixin && (
                <img
                    className="alertWeixin"
                    onClick={() => setShowAlertWeixin(false)}
                    src={alertWeixinImage}
                />
            )}
            {isNewPage ? (
                <NewPage
                    handleInstall={handleInstall}
                    isAndroid={isAndroid}
                    handleCustomer={handleCustomerApi}
                />
            ) : (
                <DownloadPage
                    handleInstall={handleInstall}
                    isAndroid={isAndroid}
                    handleCustomer={handleCustomerApi}
                />
            )}
        </div>
    );
};

export default App;
